<template>
  <div class="py-5">
    <div class="flex w-full justify-between px-5 lg:px-20">
      <NuxtLink
        :to="`https://ldseating.com/${locale}`"
        aria-label="LD Seating"
        external
      >
        <LDSLogo />
      </NuxtLink>
      <div class="flex items-center gap-2">
        <div class="mr-4 hidden gap-4 lg:flex">
          <NuxtLink
            v-for="(link, index) in links"
            :key="index"
            :to="link.url"
            external
            class="text-xl font-semibold"
          >
            {{ link.name }}
          </NuxtLink>
        </div>
        <AuthLogin />
        <LanguageSwitch />
      </div>
    </div>
    <div>
      <section
        class="container mx-auto my-10 flex items-center justify-center md:mb-32"
      >
        <div>
          <div class="mb-5">
            <h1 class="mb-2 text-7xl md:text-9xl">Nido</h1>
            <p
              class="text-pragmatica-extended text-xl font-semibold md:text-3xl"
            >
              {{ $t('homepage.subtitle') }}
            </p>
          </div>
          <img
            class="mb-10"
            height="500"
            width="1500"
            fit="cover"
            :modifiers="{ position: 'centre' }"
            src="/png/nido4.webp"
            format="webp"
            sizes="sm:100vw lg:1500px"
            quality="90"
            alt="Nido set"
            preload
          />
          <p
            class="text-pragmatica-extended mb-10 rounded-2xl font-semibold md:text-xl"
          >
            {{ $t('homepage.aboutNido') }}
          </p>
          <NuxtLink
            no-prefetch
            class="text-pragmatica-extended rounded-full bg-black px-4 py-2 text-xl text-white md:text-2xl"
            :to="localePath({ name: 'configurator' })"
          >
            {{ $t('homepage.buildOwnNido') }}
          </NuxtLink>
        </div>
      </section>
      <section
        class="mb-10 items-center justify-center bg-black py-10 text-white md:mb-32 md:py-32"
      >
        <div class="container mx-auto mb-10 md:mb-20">
          <div class="mb-5">
            <p
              class="text-pragmatica-extended max-w-md text-xl font-semibold md:text-3xl"
            >
              {{ $t('homepage.variabilityAndComfort.title') }}
            </p>
          </div>
          <div class="flex flex-col gap-5 md:gap-10 lg:flex-row">
            <p
              class="md:text-xl"
              v-html="$t('homepage.variabilityAndComfort.one')"
            />
            <p
              class="md:text-xl"
              v-html="$t('homepage.variabilityAndComfort.two')"
            />
            <p class="md:text-xl">
              {{ $t('homepage.variabilityAndComfort.three') }}
            </p>
          </div>
        </div>
        <div class="container mx-auto">
          <div
            class="mt-10 flex flex-col rounded-2xl bg-white py-10 text-black md:flex-row"
          >
            <div
              v-for="(image, index) in sizeImages"
              :key="index"
              class="flex flex-col items-center"
            >
              <img
                loading="lazy"
                width="3840"
                height="2160"
                :src="image.src"
                format="webp"
                sizes="sm:100vw lg:900px"
                quality="90"
                alt="Nido 1x1"
              />
              <p class="text-pragmatica-extended font-semibold">
                {{ image.text }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        class="container mx-auto my-10 items-center justify-center md:my-32"
      >
        <div>
          <div class="mb-5">
            <p
              class="text-pragmatica-extended max-w-md text-xl font-semibold md:text-3xl"
            >
              {{ $t('homepage.functionAndDesign.title') }}
            </p>
          </div>
          <div class="flex flex-col gap-5 md:gap-10 lg:flex-row">
            <p class="md:text-xl">
              {{ $t('homepage.functionAndDesign.one') }}
            </p>
            <p class="md:text-xl">
              {{ $t('homepage.functionAndDesign.two') }}
            </p>
            <p class="md:text-xl">
              {{ $t('homepage.functionAndDesign.three') }}
            </p>
          </div>
        </div>
        <div class="my-10 flex flex-row">
          <img
            loading="lazy"
            height="500"
            width="1500"
            fit="cover"
            :modifiers="{ position: 'centre' }"
            src="/png/nido4.webp"
            format="webp"
            quality="90"
            alt="Nido set"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
const { locale, t } = useI18n()

const sizeImages = [
  {
    text: 'Nido 1x1',
    src: '/png/nido1.webp',
  },
  {
    text: 'Nido 2x1',
    src: '/png/nido2.webp',
  },
  {
    text: 'Nido 3x1',
    src: '/png/index.webp',
  },
]

const links = computed(() => {
  return [
    {
      url: t('links.productsUrl'),
      name: t('links.products'),
    },
    {
      url: t('links.lookbookUrl'),
      name: t('links.lookbook'),
    },
    {
      url: t('links.aboutUrl'),
      name: t('links.about'),
    },
    {
      url: t('links.contactUrl'),
      name: t('links.contact'),
    },
  ]
})
</script>

<style lang="scss" scoped></style>
